import {httpGet, httpPost} from '@/api/request'
import config from '@/api/config'

const {httpApi} = config;


export const GetApi = {}

export const PostApi = {}



for (let k in httpApi) {

	/**
	 * get请求
	 * @param {Object} payload  请求参数，默认为{}
	 */
	GetApi[k] = function (payload = {}) {
		return httpGet({
			url: httpApi[k],
			method: 'get',
			params: payload
		});
	};
}

for (let k in httpApi) {
	/**
	 * post请求
	 * @param {Object} payload  请求参数，默认为{}
	 */
	PostApi[k] = function (payload = {}) {
		return httpPost({
			url: httpApi[k],
			method: 'post',
			data: payload
		});
	}
}




export default {
	GetApi: GetApi,
	PostApi: PostApi
}
