<template>
    <div :class="[!isCountryFocus && !isLogin ? 'login-height' : 'focus-height', 'input-animate']">
        <!-- <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"> -->
        <el-container class="container" v-if="isLogin">
            <el-header style="background: white;" height="auto">
                <div class="points">
                    <div>
                        <div class="count-tit">累计奖励积分</div>
                        <div class="count-val">{{ totalPoints }}</div>
                    </div>
                    <div class='border-arround' />
                    <div>
                        <div class="count-tit">剩余未兑换积分</div>
                        <div class="count-val">{{ remainingPoints }}</div>
                    </div>
                    <div class='border-arround' />
                    <div>
                        <div class="count-tit">已兑换积分</div>
                        <div class="count-val">{{ accSub(totalPoints, remainingPoints) }}</div>
                    </div>
                </div>
                <el-tabs v-model="activeTab" class='tabs-cus'>
                    <el-tab-pane label="获取记录" name="get">
                        <el-row>
                            <el-select v-model="selectedTime" placeholder="全部时间" style="margin-bottom: 15px;"
                                @change="selectedTimeChange" @focus='timeSelectFocus'>
                                <el-option label="全部时间" value="all"></el-option>
                                <el-option label="昨日" value="yesterday"></el-option>
                                <el-option label="近7天" value="last7days"></el-option>
                                <el-option label="近30天" value="last30days"></el-option>
                                <el-option label="自定义时间" value="custom"></el-option>
                            </el-select>
                        </el-row>

                        <div class="records">
                            <div v-for="(record, index) in profitRecord" :key="index"
                                :class="['record', index == 0 && 'border-top-first']">
                                <div>
                                    <div>ID: {{ record.businessOrderId }}</div>
                                    <div>订单金额: {{ record.orderAmount + ' 元' }}</div>
                                    <div>订单日期: {{ record.createTime }}</div>
                                </div>
                                <span style="font-weight: bold;font-size: 16px;">{{ `+${record.score}` }}</span>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="兑换记录" name="exchange">
                        <div v-for="(record, index) in withdrawRecord" :key="index" class="record">
                            <span>{{ '兑换时间：' + record.createTime }}</span>
                            <span style="font-weight: bold;font-size: 16px;">-{{ record.withdrawalAmount }}</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-header>
            <el-main style='padding: 0 !important;'>
            </el-main>
        </el-container>
        <div class="login-container" v-else>
            <div class="login-header">
                <h2>欢迎使用</h2>
            </div>
            <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" class="login-form"
                v-loading="loginLoading">
                <el-form-item>
                    <el-select ref="selectRef" v-model="loginForm.countryCode" filterable size='small'
                        popper-class="options-height" style="width: 30%; margin-right: 2%; margin-left: 2%;"
                        @visible-change='visibleChange' @hook:mounted="cancelReadOnly">
                        <el-option v-for="(item, index) in countryList" :key="index" :label="`+${item.value}`"
                            :value="item.value">
                            <span style="float: left;padding: 0 10px;">{{ item.value }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px;padding: 0 10px;">{{ item.label
                                }}</span>
                        </el-option>
                    </el-select>
                    <el-input v-model="loginForm.phone" placeholder="请输入您的手机号码" style="width: 60%;"></el-input>
                </el-form-item>
                <el-form-item prop="verificationCode">
                    <el-input v-model="loginForm.verificationCode" placeholder="请输入验证码"
                        style="width: 92%; margin-left: 2%;">
                        <el-button :disabled="countSecond != 0" @click="getVerificationCode" slot="append">{{
                            verificateMsg
                        }}</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleLogin" class="login-button">登录</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog title="选择时间范围" :visible.sync="customerTime" width="90%">
            <inlineCalendar ref="inlineCalendar" :defaultDate='[]' mode='during' @change="selectedCustomerTime" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="customerTime = false">取 消</el-button>
                <el-button type="primary" @click="customerTime = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { sendLoginMessageCode, login, querySaleCustomerBaseInfo, queryWithdrawRecordByCustomerId, queryProfitRecordByCustomerId, listPhonePrefixOption, autoLogin } from '../api/saleCustomer';
export default {
    name: 'SaleCustomer',
    components: {
    },
    computed: {
        verificateMsg() {
            return this.countSecond == 0 ? '获取验证码' : `${this.countSecond}秒重新获取`
        },
    },
    data() {
        return {
            totalPoints: 100,
            remainingPoints: 100,
            activeTab: 'get',
            selectedTime: 'all',
            isLogin: false,
            loginLoading: false,
            customerTime: false,
            withdrawRecord: [],
            profitRecord: [],
            profitBeginTime: '',
            profitEndTime: '',
            loginForm: {
                countryCode: 86,
                phone: '',
                verificationCode: ''
            },
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: 'blur' }
                ]
            }
            ,
            dateRange: [],
            countryList: []
            ,
            isCountryFocus: false,
            countSecond: 0,
        }
    },
    watch: {
        async customerTime(n) {
            if (!n) {
                if (this.dateRange.length == 0) {
                    this.selectedTime = ''
                    return
                }
                if (this.dateRange.length == 2) {
                    this.profitBeginTime = this.dateRange[0]
                    this.profitEndTime = this.dateRange[1]
                    await this.getProfitRecord()
                }
            }
        },
        deep: true
    },
    async mounted() {
        await this.loadCountry()
        await this.autoLogin()
    },
    async created() {
    },
    methods: {
        async loadCountry() {
            let res = await listPhonePrefixOption()
            this.countryList = []
            let _list = []
            let list = res.data
            for (let item of list) {
                if (item.children) {
                    for (let child of item.children) {
                        const index = _list.findIndex(p => p.label == child.label)
                        if (index == -1) {
                            _list.push({ label: child.label, value: child.value })
                        }
                    }
                }
            }
            this.countryList = [..._list]
        },
        async autoLogin() {
            let res = await autoLogin()
            if (res.code === 200) {
                this.isLogin = true
                await this.doAfterLogin();
                return
            }
            this.isLogin = false
        },
        async getVerificationCode() {
            this.loginLoading = true;
            let params = {
                phone: this.loginForm.phone,
                phonePrefix: this.loginForm.countryCode
            }
            let res = await sendLoginMessageCode(params);
            this.loginLoading = false;
            if (res.code === 200) {
                clearInterval()
                this.$message.success('验证码已发送!');
                this.countSecond = 60
                setInterval(() => {
                    if (this.countSecond == 0) {
                        clearInterval()
                    } else {
                        this.countSecond -= 1
                    }
                }, 1000);
                return
            }
            this.$message.error(res.msg);
        },
        async handleLogin() {
            this.$refs.loginForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('登录信息填写有误!');
                    return false;
                }
                let params = {
                    phone: this.loginForm.phone,
                    phonePrefix: this.loginForm.countryCode,
                    code: this.loginForm.verificationCode
                }
                let res = await login(params);
                if (res.code === 200) {
                    this.$message.success('登录成功!');
                    this.isLogin = true;
                    let token = res.data
                    sessionStorage.setItem('sale_customer_token', token);
                    await this.doAfterLogin();
                    return
                }
                this.$message.error(res.msg);
            });
        },
        async doAfterLogin() {
            await this.baseInfo();
            await this.getWithdrawRecord();
            await this.getProfitRecord()
        },
        async baseInfo() {
            let res = await querySaleCustomerBaseInfo();
            if (res.code === 200) {
                this.totalPoints = res.data.totalScore;
                this.remainingPoints = res.data.unusedScore;
            }
        },
        async getWithdrawRecord() {
            let res = await queryWithdrawRecordByCustomerId();
            if (res.code === 200) {
                this.withdrawRecord = res.data;
            }
        },
        async getProfitRecord() {
            let res = await queryProfitRecordByCustomerId({ startTime: this.profitBeginTime, endTime: this.profitEndTime });
            if (res.code === 200) {
                this.profitRecord = res.data;
            } else {
                this.profitRecord = []
            }
        },
        accSub(num1, num2) {
            let r1, r2, m, n;
            try { r1 = num1.toString().split(".")[1].length } catch (e) { r1 = 0 }
            try { r2 = num2.toString().split(".")[1].length } catch (e) { r2 = 0 }
            m = Math.pow(10, Math.max(r1, r2));
            n = (r1 >= r2) ? r1 : r2;
            return ((num1 * m - num2 * m) / m).toFixed(n);
        },
        formatDate(data, format) {
            let year = data.getFullYear();
            let month = data.getMonth() + 1;
            let day = data.getDate();
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();

            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            hour = hour < 10 ? '0' + hour : hour;
            minute = minute < 10 ? '0' + minute : minute;
            second = second < 10 ? '0' + second : second;

            return format.replace('YYYY', year).replace('MM', month).replace('DD', day).replace('hh', hour).replace('mm', minute).replace('ss', second);
        },
        async selectedTimeChange(val) {
            this.customerTime = false
            //全部时间
            switch (val) {
                case 'all':
                    this.profitBeginTime = null;
                    this.profitEndTime = null;
                    break;
                case 'yesterday':
                    this.profitBeginTime = this.formatDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 'YYYY-MM-DD 00:00:00');
                    this.profitEndTime = this.formatDate(new Date(), 'YYYY-MM-DD 00:00:00');
                    break;
                case 'last7days':
                    this.profitBeginTime = this.formatDate(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), 'YYYY-MM-DD 00:00:00');
                    this.profitEndTime = this.formatDate(new Date(), 'YYYY-MM-DD 00:00:00');
                    break;
                case 'last30days':
                    this.profitBeginTime = this.formatDate(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), 'YYYY-MM-DD 00:00:00');
                    this.profitEndTime = this.formatDate(new Date(), 'YYYY-MM-DD 00:00:00');
                    break;
                case 'custom':
                    this.dateRange = []
                    this.customerTime = true
                    break;
            }
            if (this.$refs.inlineCalendar) {
                setTimeout(() => {
                    this.$refs.inlineCalendar.selectDate = []
                    this.$refs.inlineCalendar.init()
                }, 50);
            }
            if (val != 'custom') {
                await this.getProfitRecord()
            }
        },
        timeSelectFocus() {
            this.selectedTime = ""
            this.dateRange = []
        },
        selectedCustomerTime(date) {
            this.dateRange = date.map(item => {
                return this.formatDate(item.$d, 'YYYY-MM-DD 00:00:00')
            })
        }
        ,
        visibleChange(val) {
            this.isCountryFocus = val
            this.cancelReadOnly(val)
        },
        cancelReadOnly(val) {
            this.$nextTick(() => {
                if (!val) {
                    const { selectRef } = this.$refs
                    const input = selectRef.$el.querySelector('.el-input__inner')
                    input.removeAttribute('readonly')
                    this.$refs.selectRef.blur()
                }
            })
        }
    }
}
</script>

<style>
.options-height .el-select-dropdown__wrap {
    max-height: 200px !important;
}
</style>
<style lang='scss' scoped>
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.container {
    background-color: #f2f3f5;
    width: 100%;
    max-width: 390px;
    height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.points {
    text-align: center;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.count-tit {
    font-size: 14px;
}

.count-val {
    font-size: 18px;
    font-weight: bold;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    border-bottom: 1px solid #ddd;
}

.tabs div {
    cursor: pointer;
    font-size: 16px;
    padding-bottom: 10px;
}

.tabs .active {
    font-weight: bold;
    border-bottom: 2px solid black;
}

.login-height {
    padding-top: 15vh;
}

.focus-height {
    padding-top: 1vh;
}

.input-animate {
    height: 100vh;
    // transition: padding-top .05s;
    width: 100vw;
    background: #f2f3f5;
}

.records {
    flex: 1;
    overflow-y: auto;
}

.record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    font-size: 13px;
    line-height: 26px;
}

.border-top-first {
    border-top: 1px solid #eee;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.login-header h2 {
    margin: 0;
    font-size: 24px;
}

.login-container {
    background-color: white;
    width: 90%;
    max-width: 390px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
}

.login-form {
    width: 100%;
}

.login-button {
    margin: 0 auto;
    display: block;
    width: 60%;
}

.border-arround {
    width: 1px;
    height: auto;
    background: #C0C4CC;
}

.tabs-cus {
    /deep/ .el-tabs__header {
        border-bottom: none;
    }

    /deep/ .el-tabs__nav {
        width: 100%;
        text-align: center;
    }

    /deep/ .el-tabs__item {
        width: 50%;
        text-align: item;
    }
}
</style>