<template>
  <div id="app">
    <SaleCustomer/>
    <div style="position: fixed; bottom: 0; width: 100%; text-align: center; padding: 10px 0;">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color: grey; font-size: small;">粤ICP备2020141957号-5</a>
    </div>
  </div>
</template>

<script>
import SaleCustomer from './components/SaleCustomer.vue'

export default {
  name: 'App',
  components: {
    SaleCustomer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
