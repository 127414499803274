
import {PostApi } from '@/api'

const TOKEN_KEY = 'sale_customer_token'



export async function sendLoginMessageCode(data) {
    return await PostApi.sendLoginMessageCode(data)
}

export async function login(data) {
    return await PostApi.saleCutomerLogin(data)
}


export async function querySaleCustomerBaseInfo() {
    let patload = {
        "customer-token": getToken()
    }
    return await PostApi.querySaleCustomerBaseInfo(patload)
}

export async function queryProfitRecordByCustomerId(data) {
    data["token"] = getToken()
    if(!data.startTime){
        data.startTime = "2020-01-01 00:00:00"
    }
    let now = new Date()
    if(!data.endTime){
        data.endTime = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " 23:59:59"
    }
    console.log(data)
    return await PostApi.queryProfitRecordByCustomerId(data)
}

export async function queryWithdrawRecordByCustomerId() {
    let patload = {
        "customer-token": getToken()
    }
    return await PostApi.queryWithdrawRecordByCustomerId(patload)

}

export async function listPhonePrefixOption() {
    return await PostApi.listPhonePrefixOption()

}

export async function autoLogin() {
    let patload = {
        "customer-token": getToken()
    }
    return await PostApi.autoLogin(patload)
}


function getToken() {
    return sessionStorage.getItem(TOKEN_KEY)
}
