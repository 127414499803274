
export const BASE_API = process.env.NODE_ENV === 'production' ? '/' : '/'
export const ApiPrefix = process.env.NODE_ENV === 'production' ? 'https://api.weseego.com' : 'https://api.weseego.com'        //product


export default {
	BASE_API: BASE_API,
	ApiPrefix: ApiPrefix,

	// http://www.netphone.com
	imagePathPrefix: process.env.NODE_ENV === 'production' ? '/' : '/',

	brand: "wx",
	pv: "web",
	v: "1.0.0",
	package_name: "com.weseego.h5",
	channel: "1",

}