/**
 * 一些简单的配置 ， 都在这里
 */

/**
 * 开发环境使用，正式环境请设置空
 */

import global_var from '../global_var'

const ApiPrefix = global_var.ApiPrefix;      //prod

export default {

    httpApi: {
        saleCutomer: `${ApiPrefix}/shop/external/saleCustomer`, // 销售积分用户
        sendLoginMessageCode: `${ApiPrefix}/shop/external/saleCustomer/sendLoginMessageCode`, // 发送登录验证码
        saleCutomerLogin: `${ApiPrefix}/shop/external/saleCustomer/checkLoginMessageCode`, // 销售积分用户登录
        querySaleCustomerBaseInfo: `${ApiPrefix}/shop/external/saleCustomer/querySaleCustomerBaseInfo`, // 查询销售积分用户基本信息 
        queryProfitRecordByCustomerId: `${ApiPrefix}/shop/external/saleCustomer/queryProfitRecordByCustomerId`, // 查询销售积分用户收益记录
        queryWithdrawRecordByCustomerId: `${ApiPrefix}/shop/external/saleCustomer/queryWithdrawRecordByCustomerId`, // 查询销售积分用户提现记录
        listPhonePrefixOption: `${ApiPrefix}/product-travel/country/listPhonePrefixOption`, // 获取国家电话区号
        autoLogin: `${ApiPrefix}/shop/external/saleCustomer/checkLoginStatus`, // 自动登录
    }
}
