
import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

import global_var from "../global_var";

// create an axios instance
const service = axios.create({
    baseURL: global_var.BASE_API, // api的base_url
    timeout: 10000 // request timeout
})






// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    let token = getToken()
    // console.log('Cookie中的token[' + getToken() + ']')
    if (token) {
        config.headers['X-Token'] = token // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    // console.log(config)
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data
        return res
    },

    (error) => {
        console.error('net request err:', error)// for debug
        Message({ message: error.message, type: 'warning' })
        return Promise.reject(error)
    })


export function httpGet(option) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.params }
        // console.log(`GET请求${option.url}参数:${JSON.stringify(params)}`)
        let conf = {
            params: params,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
        service.get(option.url, conf).then((response) => {
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

function qs(params) {
    let pair_list = [];
    for (let it in params) {
        // var paramVal = StringUtil.EncodeUtf8(params[keys[i]]);
        let paramVal = params[it];
        if (paramVal === undefined) {
            continue;
        }
        pair_list.push(encodeURIComponent(it) + "=" + encodeURIComponent(paramVal))
    }
    return pair_list.join("&");
}

export function httpPost(option) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.data }
        // console.log(`POST请求${option.url}参数:${JSON.stringify(params)}`)
        // console.log("host: " + global_var.BASE_API)
        let data = qs(params)
        // console.log(data)
        let conf = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
        service.post(option.url, data, conf).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function httpPostWithHeader(option, token) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.data }
        // console.log(`POST请求${option.url}参数:${JSON.stringify(params)}`)
        // console.log("host: " + global_var.BASE_API)
        let data = qs(params)
        // console.log(data)
        let conf = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'customer-token': token
            }
        }
        service.post(option.url, data, conf).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


/**
 * 构建请求地址
 * @param path 地址
 * @param payload 参数
 * @returns {string} 返回构建后的请求地址
 */
export function buildRequestURL(path, payload) {
    let params = { ...payload }
    params['token'] = getToken()
    let data = qs(params)
    return global_var.BASE_API + path + '?' + data
}


// export default service
